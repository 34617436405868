import styled from "styled-components"

import { HomesDropdown } from "src/components/Dropdown/HomesDropdown"
import { TMaybeHome } from "src/data/homes/types/homeTypes"
import {
  IProviderHomeResponse,
  IResponseServiceProviderResponse,
} from "src/data/homes/types/responseServiceTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { Heading2Mixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export const HOME_SELECT_FORM_ID = "select_home"

export function HomeSelectStep({
  selectedHome,
  onSubmit,
  onSelected,
  providerInformation,
}: {
  selectedHome?: TMaybeHome
  onSubmit: () => void
  onSelected: (home: TMaybeHome) => void
  providerInformation:
    | Pick<IResponseServiceProviderResponse, "name" | "id">
    | undefined
}) {
  const { t, langKeys } = useTranslate()

  function handleDisabledOption(
    home: TMaybeHome,
    providerMap?: IProviderHomeResponse
  ) {
    const providerId = providerInformation?.id
    const providerList = providerMap?.find((p) => p.home_id === home?.home_id)
    // If we have a provider Id find the correct provider, otherwise just pick
    // one.
    const correctProviderInfo = providerId
      ? providerList?.providers.find((p) => p.id === providerId)
      : providerList?.providers[0]

    // If we have providerInfo then the option is enabled
    return !correctProviderInfo || !!home?.response_service?.active
  }

  return (
    <div>
      <Title>
        {providerInformation
          ? t(langKeys.placeholder_web, {
              text: `Set up Guard Assist with ${providerInformation.name}`,
            })
          : t(langKeys.response_service_wizard_home_select_title)}
      </Title>

      <FormBox
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
        id={HOME_SELECT_FORM_ID}
      >
        <HomesDropdown
          value={selectedHome || null}
          onSelect={onSelected}
          renderOption={(option, providerList) => (
            <RenderHomeOption
              home={option}
              providerMap={providerList}
              providerId={providerInformation?.id}
            />
          )}
          getOptionDisabled={(option, providerMap) =>
            handleDisabledOption(option, providerMap)
          }
          fetchProviders
        />
      </FormBox>
    </div>
  )
}

function RenderHomeOption({
  home,
  providerMap,
  providerId,
}: {
  home: TMaybeHome
  providerMap?: IProviderHomeResponse
  providerId?: IResponseServiceProviderResponse["id"]
}) {
  const providerList = providerMap?.find(
    ({ home_id }) => home_id === home?.home_id
  )
  if (!home || !providerList) {
    return null
  }

  return (
    <HomeRow>
      <div>{home.name}</div>
      <Status
        providerList={providerList}
        providerId={providerId}
        hasActivatedGuardAssist={!!home.response_service?.active}
      />
    </HomeRow>
  )
}

function Status({
  providerList,
  providerId,
  hasActivatedGuardAssist,
}: {
  providerList: IProviderHomeResponse[number]
  providerId?: IResponseServiceProviderResponse["id"]
  hasActivatedGuardAssist: boolean
}) {
  const { t, langKeys } = useTranslate()

  // If we have a provider Id find the correct provider, otherwise just pick
  // one.
  const providerInformation = providerId
    ? providerList.providers.find((p) => p.id === providerId)
    : providerList.providers[0]

  if (!providerInformation) {
    return (
      <MBanner type="neutral" size="small">
        {t(langKeys.response_partners_outside_coverage_area)}
      </MBanner>
    )
  }

  if (providerInformation && hasActivatedGuardAssist) {
    return (
      <MBanner type="good" size="small">
        {t(langKeys.active)}
      </MBanner>
    )
  }

  return <></>
}

const HomeRow = styled.div`
  display: flex;
  gap: ${spacing.S};
`

const FormBox = styled.form`
  display: grid;
  grid-gap: ${spacing.XL};
  max-width: 70ch;
  margin-bottom: ${spacing.L};
`

const Title = styled.div`
  margin-bottom: ${spacing.XL2};
  ${Heading2Mixin};
`
