import { paths } from "@minuthq/minut-api-types/schema"

import { BillingPeriodUnit, CurrencyCode } from "src/components/Account/types"
import { IHome } from "src/data/homes/types/homeTypes"
import { PLAN } from "src/data/user/user"

export enum ResponseServiceToggleAction {
  REGISTER = "register",
  CANCEL = "cancel",
}

export enum AddonType {
  RESPONSE_SERVICE = "response_service",
}

//This should be aligned with chargebee subscription type, since it exposes some of the same fields, but not all
export interface IEstimateSubscription {
  plan: typeof PLAN.standard | typeof PLAN.pro
  planId: string
  current_term_end: string
  unit_cost: number //in cents?
  quantity: number
  billing_period: BillingPeriodUnit.MONTH | BillingPeriodUnit.YEAR
  total_renewal_cost: number
  currency?: CurrencyCode
}

//move to subscription types?
export interface IEstimateAddon {
  name: string
  dispatch_cost: number
  unit_cost: number
  quantity: number
  total_renewal_cost: number
  type: AddonType
  trial_end?: string
}

export interface IResponseServiceToggleEstimateResponse {
  subscription: IEstimateSubscription
  addons: IEstimateAddon[]
}

export interface IResponseServiceErrorResponse {
  message: string
  error_key: string
}

type TResponseServicePatchOverride =
  | { active: true; provider: string } // enforce `provider` if active=true
  | { active?: false; provider?: undefined | string }
export type IResponseServicePatch =
  paths["/organizations/{organization_id}/homes/{home_id}/response_service"]["patch"]["requestBody"]["content"]["application/json"] &
    TResponseServicePatchOverride

export type IProviderHomeResponse =
  paths["/organizations/{organization_id}/provider/homes"]["get"]["responses"]["200"]["content"]["application/json"]
export interface IResponseServicePatchResponse {
  available: boolean
  automatic_dispatch_disabled: boolean
  configured: boolean
  active: boolean
  subscription_status: ResponseServiceSubscriptionStatus
  current_term_end?: string
  dispatch_status?: DispatchStatus
  dispatch_registered_at?: string
}

export type IResponseService = Exclude<IHome["response_service"], undefined>
export interface IResponseServiceCalloutEstimates {
  [key: string]: {
    name: string
    dispatch_cost: number
    unit_cost: number
    provider: string
    billing_period: BillingPeriodUnit.MONTH | BillingPeriodUnit.YEAR
    currency: string
  }
}

export type IResponseServiceProviderResponse =
  paths["/response_service/providers/{provider_id}"]["get"]["responses"]["200"]["content"]["application/json"]

export type IResponseServiceProvidersResponse =
  paths["/response_service/providers"]["get"]["responses"]["200"]["content"]["application/json"]

export type ResponseServiceSubscriptionStatus =
  IResponseService["subscription_status"]

type DispatchStatus =
  | "requested"
  | "acknowledged"
  | "in_progress"
  | "arrived"
  | "cancelled"
  | "completed"
